import { useState, useEffect } from 'react';
import Axios from 'axios';

import authService from '../../../utils/auth-service';

const config = window.runConfig

function useUsersTable() {
  const [sort, setSort] = useState({ category: 'id', value: 'asc' });
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [userNotFound, setUserNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    users.sort(
      (a, b) => {
        if (sort.value === 'asc') {
          if (b[sort.category] > a[sort.category]) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (b[sort.category] < a[sort.category]) {
            return 1;
          } else {
            return -1;
          }
        }
      },
      [sort.value],
    );
  });

  useEffect(() => {
    const loginData = authService.getAuthInfo();
    // eslint-disable-next-line no-undef
    let pulseProfilesUrl = 'api/profiles';
    const apiUrl = new URL(pulseProfilesUrl, config.API_URL);
    Axios.get(apiUrl, {
      headers: { Authorization: `Bearer  ${loginData.accessToken}` },
    })
      .then(response => {
        if (response.data !== null) {
          setUsers(response.data);
        }
      })
      .catch(error => error);
  }, []);

  function getUser(e, email) {
    e.preventDefault();
    setLoading(true);
    const loginData = authService.getAuthInfo();
    let pulseProfilesFindUrl = 'api/profiles/Find?';
    const apiUrl = new URL(pulseProfilesFindUrl, config.API_URL);
    apiUrl.search = new URLSearchParams({
      email,
    });
    Axios.get(apiUrl, {
      headers: { Authorization: `Bearer  ${loginData.accessToken}` },
    })
      .then(response => {
        setUser(response.data);
      })
      .catch(e => {
        setUserNotFound(true);
        setLoading(false);
      });
  }

  function setColumnSort(category, value) {
    setSort({ category, value });
  }

  return {
    setColumnSort,
    sort,
    users,
    user,
    getUser,
    userNotFound,
    setUserNotFound,
    loading,
  };
}

export default useUsersTable;
