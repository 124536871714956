import React, { Component , useState, setState} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';

import authService from '../../utils/auth-service';
import CustomerInformationTable from './CustomerInformationTable';
import AssetInformationTable from './AssetInformationTable';
import UserTitle from './UserTitle';

import Search from './Search';
import Confirm from './Confirm';

const config = window.runConfig

class UserData extends Component {
  constructor(props) {
    super(props);

    this.state = {

      email: "",
      resultStatus: { error: null, loading: false, success: false },

      data: null,
      transferAssetsStatus: { error: null, loading: false, success: false },
      resendPinStatus: {
        error: null,
        loading: false,
        success: false,
      },
      forgotPasswordStatus: {
        error: null,
        loading: false,
        success: false,
      },
    };
    this.getAssetIds = this.getAssetIds.bind(this);
    this.transferAssets = this.transferAssets.bind(this);
    this.initiateForgotPassword = this.initiateForgotPassword.bind(this);
    this.resendPin = this.resendPin.bind(this);

    this.handleInputEmail = this.handleInputEmail.bind( this );
  }


  handleStatusChange = (event) => {
    this.setState({ email: event.target.value });
  };

  componentDidMount() {
    const { match } = this.props;
    this.state = {
      userId: match.params.id,
      isMounted: true,
    };
    const { userId } = this.state;
    this.getData(userId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data === null) {
      this.getAssets(this.state.data.userData.id);
    }
  }

  getData(userId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const profilesUrl = `api/Profiles/${userId}`;
    const profilesApiUrl = new URL(profilesUrl, config.API_URL);
    const t = this;

    Axios.get(profilesApiUrl, {
      headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` },
    })
      .then(response => {
        if (response.data) {
          return response.data;
        }
      })
      .then(result => {
        const userData = result;
        t.setState({
          data: {
            userData,
          },
        });
      });
  }

  getAssets(userId) {
    const pulseCheckLoginData = authService.getAuthInfo();
    const assetsUrl = `api/PulseApp/Dashboard/${userId}`;
    const assetsApiUrl = new URL(assetsUrl, config.API_URL);
    const t = this;

    Axios.get(assetsApiUrl, {
      headers: { Authorization: `Bearer ${pulseCheckLoginData.accessToken}` },
    })
      .then(response => {
        if (response.data) {
          return response.data;
        }
      })
      .then(result => {
        const assetsData = result;
        const canAssign = authService
          .getAuthInfo()
          .profileModel.permissions.includes('ManageAllPulseChecks');
        const canEdit = authService
          .getAuthInfo()
          .profileModel.permissions.includes('ManageAllPulseChecks');
        t.setState({
          data: {
            ...this.state.data,
            assetsData,
          },
          canEdit,
          canAssign,
        });
      });
  }

  getAssetIds({ assets }) {
    return assets.map(asset => asset.assetId);
  }

  //handle Transfer Asset status message
  handleTransferAssetMessage( error) {
    //console.log('Init handleTransferAssetMessage Loading');
    if ( this.state.resultStatus.loading ) {
        //console.log('Loading = true ');
        return <div></div>;
    }    
    else {
      if ( this.state.resultStatus.success ) {
        //console.log('Loading = false | show success');
          return <button className='btn btn-success' >Success</button>;
      }
      if ( this.state.resultStatus.error ) {
        //console.log('Loading = false |  show error ' + this.state.resultStatus.error );
        return <button className='btn btn-danger' >Error: { this.state.resultStatus.error }</button>;
      }
      else {
        //console.log('Loading = false | error = falce');
        return <div></div>;
      }
    }
  }

  handleInputEmail( event ) {    
    this.setState( { email: event.target.value } );
    //console.log( 'handleTextInputEmail = ' +  this.state.email );    
  }

  transferAssets(e, email) {
    e.preventDefault();
    this.setState({
      transferAssetsStatus: {
        ...this.state.transferAssetsStatus,
        loading: true,
      },

        resultStatus: {
          ...this.state.resultStatus,
          loading: true,
        },

    });
    const loginData = authService.getAuthInfo();
    const accessGrantsUrl = 'api/Assets/Grant';
    const apiUrl = new URL(accessGrantsUrl, config.API_URL);
    const assets = this.getAssetIds(this.state.data.assetsData);
    Axios.post(
      apiUrl,
      { profiles: [email], assets },
      { headers: { Authorization: `Bearer  ${loginData.accessToken}` } },
    )
      .then(response => {
        if (response.status === 200) {

          //alert("Success");
          this.setState({
            transferAssetsStatus: {
              ...this.state.transferAssetsStatus,
              loading: false,
              success: true,
            },

            resultStatus: {
              ...this.state.resultStatus,
              loading: false,
              success: true,
            },

          });
        }
      })
      .catch(e => {
        //alert("Error: " + e.message );

        this.setState({
          //resultStatus: "Error: " + e.message,
          transferAssetsStatus: {
            ...this.state.transferAssetsStatus,
            loading: false,
            error: e.message,
          },

          resultStatus: {
            ...this.state.resultStatus,
            loading: false,
            error: e.message,
          },

        });
      })
      .then(() =>
        setTimeout(() => {
          this.setState({
            transferAssetsStatus: {
              loading: false,
              success: false,
              error: false,
            },

            resultStatus: {
              loading: false,
              success: false,
              error: false,
            },

          });
        }, 2000),
      );
  }

  initiateForgotPassword(e) {
    e.preventDefault();
    this.setState({
      forgotPasswordStatus: {
        ...this.state.forgotPasswordStatus,
        loading: true,
      },
    });
    const loginData = authService.getAuthInfo();
    const initiateForgotPasswordUrl = 'api/Profiles/ForgotPassword';
    const apiUrl = new URL(initiateForgotPasswordUrl, config.API_URL);
    const { email } = this.state.data.userData;
    Axios.post(
      apiUrl,
      { email },
      { headers: { Authorization: `Bearer  ${loginData.accessToken}` } },
    )
      .then(response => {
        if (response.status === 200) {
          this.setState({
            forgotPasswordStatus: {
              ...this.state.forgotPasswordStatus,
              loading: false,
              success: true,
            },
          });
        }
      })
      .catch(e => {
        this.setState({
          forgotPasswodStatus: {
            ...this.state.forgotPasswordStatus,
            loading: false,
            error: e.errorMessage,
          },
        });
      })
      .then(() =>
        setTimeout(() => {
          this.setState({
            forgotPasswordStatus: {
              loading: false,
              success: false,
              error: false,
            },
          });
        }, 2000),
      );
  }

  resendPin(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      resendPinStatus: { ...this.state.resendPinStatus, loading: true },
    });
    const loginData = authService.getAuthInfo();
    const resendPinUrl = 'api/Profiles/ResendCode';
    const apiUrl = new URL(resendPinUrl, config.API_URL);
    const { email } = this.state.data.userData;
    Axios.post(
      apiUrl,
      { email },
      { headers: { Authorization: `Bearer  ${loginData.accessToken}` } },
    )
      .then(response => {
        if (response.status === 200) {
          this.setState({
            ...this.state,
            resendPinStatus: {
              ...this.state.resendPinStatus,
              loading: false,
              success: true,
            },
          });
        }
      })
      .catch(e => {
        this.setState({
          ...this.state,
          resendPinStatus: {
            ...this.state.resendPinStatus,
            loading: false,
            error: e.message,
          },
        });
      })
      .then(() =>
        setTimeout(() => {
          this.setState({
            resendPinStatus: { loading: false, success: false, error: false },
          });
        }, 2000),
      );
  }

  render() {
    const {
      data,
      userId,
      transferAssetsStatus,
      resendPinStatus,
      forgotPasswordStatus,
    } = this.state;

    return (
      <div className="Data-container">
        {data !== null && userId !== null ? (
          <div className="Page-two-container-row">
            <div className="Page-two-container-tables">
              <UserTitle userData={data.userData} />
              <div className="Page-two-top">
                <div className="Pulse-check-data-table-div">
                  <div className="Pulse-check-data-table-left-div">
                    <CustomerInformationTable userData={data.userData} />
                  </div>
                  <div className="Pulse-check-data-table-right-div">
                    {data.assetsData ? (
                      <AssetInformationTable assetsData={data.assetsData} />
                    ) : null}
{/*

                    <Search
                      method={this.transferAssets}
                      status={{ ...transferAssetsStatus }}
                    >
                      Transfer Assets
                    </Search>
*/}
                    <div className="text-center">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="email"
                        onChange = { this.handleInputEmail }
                      />

                      <Confirm
                          onConfirm={ ( event ) => {                               
                              console.log( 'email = ' + event );
                              console.log("Transfer email = " + this.state.email );
                              //alert('Confirmed');
                              this.transferAssets( event, this.state.email );
                              }                                                                          
                          }
                          body="Are you sure you want to copy asset to this new user?"
                          confirmText="Confirm Copy Asset"
                          title="Copy Asset"      
                          >
                          <button 
                          class="btn btn-primary btn-lg"
                          >Copy Assets
                          </button>
                      </Confirm>
                      
                    <div>
                    { this.handleTransferAssetMessage( ) }
                    </div>

                    </div>

                    <Search
                      method={this.initiateForgotPassword}
                      data={data.userData.email}
                      status={{ ...forgotPasswordStatus }}
                    >
                      Initiate Forgot Password
                    </Search>
                    <Search
                      method={this.resendPin}
                      data={data.userData.email}
                      status={{ ...resendPinStatus }}
                    >
                      Resend PIN
                    </Search>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

UserData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(UserData);
