import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';

import authService from '../../utils/auth-service';
import skfLogo from '../../images/skf-logotype-center.png';
import LoginFailed from './LoginFailed';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loggingIn: false,
      loginFailed: false,
      error: null,
    };

    this.signIn = this.signIn.bind(this);
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  signIn(e) {
    this.setState({ loggingIn: true });
    e.preventDefault();
    const { email, password } = this.state;

    authService
      .signIn(email, password)
      .then(res => {
        if (!authService.isAuthorized(res.data.profileModel.permissions)) {
          throw new Error('Unauthorized');
        }
        authService.saveAuthInfo(res.data);

        const { onLoginSuccess, history } = this.props;

        onLoginSuccess(authService.getAuthInfo());
        history.push('/');
      })
      .catch(e => {
        this.setState({
          loginFailed: true,
          loggingIn: false,
          error: e.message,
        });
      });
  }

  render() {
    const { loginFailed, email, password, loggingIn, error } = this.state;

    return (
      <div className="text-center">
        <img src={skfLogo} alt="" />
        <h1 className="h3 mb-3">Please Sign In</h1>
        <Form className="form-signin" onSubmit={this.signIn}>
          {loginFailed ? <LoginFailed error={error} /> : null}
          <FormGroup>
            <Input
              className="form-control"
              type="email"
              name="email"
              id="emailInput"
              placeholder={'Email Address'}
              value={email}
              onChange={this.onInputChange}
            />

            <Input
              className="form-control"
              type="password"
              name="password"
              id="passwordInput"
              placeholder="Password"
              value={password}
              onChange={this.onInputChange}
            />
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            size="lg"
            disabled={loggingIn}
            className={loggingIn ? 'save-animation' : ''}
            block
          >
            {!loggingIn ? 'Sign In' : ''}
          </Button>
        </Form>
      </div>
    );
  }
}

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Login;
