/* eslint-disable arrow-parens */
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import './App.css';

import MainNavbar from './components/navigation/MainNavbar';
import Login from './components/authentication/Login';
import PrivateRoute from './components/authentication/PrivateRoute';
import LoggedInRoute from './components/authentication/LoggedInRoute';
import UsersTable from './components/pulse-user/pulse-user-table/UsersTable';
import UserData from './components/pulse-user/UserData';
import authService from './utils/auth-service';
import LanguageService from './utils/language-service';
import TokenInterceptor from './components/authentication/TokenInterceptor';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: authService.isAuthenticated(),
      authInfo: authService.getAuthInfo(),
    };
  }

  onLoginSuccess = authInfo => {
    LanguageService.setLanguage(LanguageService.resolveLanguage());

    this.setState({
      isAuthenticated: true,
      authInfo,
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    });
  };

  onLogoutSuccess = () => {
    authService.signOut();
    LanguageService.setLanguage(LanguageService.resolveLanguage());

    this.setState({
      isAuthenticated: authService.isAuthenticated(),
      authInfo: authService.getAuthInfo(),
      language: LanguageService.getLanguage(),
      languageMessages: LanguageService.getMessages(),
    });
  };

  render() {
    const { isAuthenticated, authInfo } = this.state;
    const email =
      authInfo && authInfo.profileModel ? authInfo.profileModel.email : null;

    return (
      <div className="App">
        <TokenInterceptor onRefreshTokenFailure={this.onLogoutSuccess} />
        <BrowserRouter>
          <React.Fragment>
            <MainNavbar
              isAuthenticated={isAuthenticated}
              email={email}
              onLogoutSuccess={this.onLogoutSuccess}
            />
            <Container fluid id="container">
              <LoggedInRoute
                exact
                path="/login"
                isAuthenticated={isAuthenticated}
                onLoginSuccess={this.onLoginSuccess}
                component={Login}
              />
              <PrivateRoute
                exact
                path="/"
                isAuthenticated={isAuthenticated}
                component={UsersTable}
              />
              <PrivateRoute
                path="/userData/:id"
                isAuthenticated={isAuthenticated}
                component={UserData}
              />
            </Container>
          </React.Fragment>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
