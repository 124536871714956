import React from 'react';
import PropTypes from 'prop-types';

const AssetInformationTable = ({ assetsData: { assets } }) => (
  <table className="Page-two-table">
    <tbody>
      <tr>
        <th colSpan="2" className="Table-title">
          Asset Information
        </th>
      </tr>
      <tr>
        <td>Total Assets</td>
        <td>{assets.length}</td>
      </tr>
    </tbody>
  </table>
);

AssetInformationTable.defaultProps = {
  assetsData: PropTypes.shape({
    application: PropTypes.string,
    assetType: PropTypes.string,
    assetName: PropTypes.string,
    assetTag: PropTypes.string,
    manufacturer: PropTypes.string,
    modelNumber: PropTypes.string,
    serialNumber: PropTypes.string,
    operatingSpeed: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isoThresholdCalculationSettings: PropTypes.shape({
      flexibility: PropTypes.string.isRequired,
      bearingBoreSize: null,
      bearingBoreSizeUnit: null,
    }).isRequired,
    powerTransmissions: PropTypes.string,
  }),
};

AssetInformationTable.propTypes = {
  assetData: PropTypes.shape({
    application: PropTypes.string,
    assetType: PropTypes.string,
    assetName: PropTypes.string,
    assetTag: PropTypes.string,
    manufacturer: PropTypes.string,
    modelNumber: PropTypes.string,
    serialNumber: PropTypes.string,
    operatingSpeed: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    powerTransmissions: PropTypes.string,
  }),
};

export default AssetInformationTable;
