import React from 'react';
import PropTypes from 'prop-types';

const CustomerInformationTable = ({ userData }) => (
  <table className="Page-two-table">
    <thead>
      <tr>
        <th colSpan="2" className="Table-title">
          Customer Information
        </th>
      </tr>
    </thead>
    {userData ? (
      <tbody>
        <tr>
          <td>First Name</td>
          <td>{userData.firstName}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{userData.lastName}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{userData.email}</td>
        </tr>
        <tr>
          <td>Company</td>
          <td>{userData.company}</td>
        </tr>
        <tr>
          <td>Industry</td>
          <td>{userData.industry}</td>
        </tr>
        <tr>
          <td>Facility</td>
          <td>{userData.facility}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td>{userData.country}</td>
        </tr>
        <tr>
          <td>Zip Code</td>
          <td>{userData.zipCode}</td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td>First Name</td>
        </tr>
        <tr>
          <td>Last Name</td>
        </tr>
        <tr>
          <td>Email</td>
        </tr>
        <tr>
          <td>Company</td>
        </tr>
        <tr>
          <td>Industry</td>
        </tr>
        <tr>
          <td>Facility</td>
        </tr>
        <tr>
          <td>Country</td>
        </tr>
        <tr>
          <td>Zip Code</td>
        </tr>
      </tbody>
    )}
  </table>
);

CustomerInformationTable.defaultProps = {
  pulseCheckData: {
    preferredEmailContact: '',
    preferredPhoneContact: '',
    requester: {
      industry: '',
      facility: '',
      country: '',
      zipCode: '',
      firstName: '',
      lastName: '',
    },
  },
};

CustomerInformationTable.propTypes = {
  pulseCheckData: PropTypes.shape({
    preferredEmailContact: PropTypes.string,
    preferredPhoneContact: PropTypes.string,
    requester: PropTypes.shape({
      industry: PropTypes.string,
      facility: PropTypes.string,
      country: PropTypes.string,
      zipCode: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};

export default CustomerInformationTable;
