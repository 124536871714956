import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoggedInRoute = ({
  component: Component, isAuthenticated, region, ...rest
}) => (
  <Route
    {...rest}
    render={props => (isAuthenticated
      ? <Redirect to={{ pathname: region, state: { from: props.location } }} />
      : <Component {...props} {...rest} />)
    }
  />
);

export default LoggedInRoute;
