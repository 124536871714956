import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const UserTitle = ({ userData, cancelled }) => (
  <div className="Page-two-title">
    <h3>User Data for {userData.email}</h3>
    <p>User ID: {userData.id}</p>
    <p>
      Created Date: {moment(new Date(userData.createdDate)).format('hh:mm a')}{' '}
      {moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()}{' '}
      {moment(new Date(userData.createdDate)).format('l')}
    </p>
  </div>
);

UserTitle.defaultProps = {
  cancelled: false,
  profileData: {
    email: '',
    company: '',
    country: '',
  },
  pulseCheckData: {
    requestedDate: '',
  },
};

UserTitle.propTypes = {
  profileData: PropTypes.shape({
    email: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
  }),
  pulseCheckData: PropTypes.shape({
    requiresEmailResponse: PropTypes.bool,
    requestedDate: PropTypes.string,
    requester: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

export default UserTitle;
