import React from 'react';
import { Alert } from 'reactstrap';

const LoginFailed = ({ error }) => (
  <Alert color="danger">
    {error === 'Unauthorized' ? error : 'The username/password is incorrect.'}
  </Alert>
);

export default LoginFailed;
