/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  NavbarBrand,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

const AuthenticatedNavItem = ({ onLogout, email, onSwitchLanguageTo }) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      {email}
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem onClick={onLogout}>Logout</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

AuthenticatedNavItem.defaultProps = {
  email: '',
};

AuthenticatedNavItem.propTypes = {
  onLogout: PropTypes.func.isRequired,
  email: PropTypes.string,
};

const MainNavbar = ({
  isAuthenticated,
  onLogoutSuccess,
  email,
  onSwitchLanguageTo,
  region,
}) => (
  <Navbar className="navbar navbar-dark bg-primary">
    <NavbarBrand href={region} className="mr-auto">
      Pulse
    </NavbarBrand>
    <Nav>
      {isAuthenticated ? (
        <AuthenticatedNavItem
          onLogout={onLogoutSuccess}
          email={email}
          onSwitchLanguageTo={onSwitchLanguageTo}
        />
      ) : null}
    </Nav>
  </Navbar>
);

MainNavbar.defaultProps = {
  email: '',
  region: '',
};

MainNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string,
  region: PropTypes.string,
};

export default withRouter(MainNavbar);

MainNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onLogoutSuccess: PropTypes.func.isRequired,
  email: PropTypes.string,
  region: PropTypes.string,
};
