import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import Search from '../Search';

import useUsersTable from './UseUsersTable';
import Sorting from './Sorting';

function CreateUsersTable() {
  const history = useHistory();
  const {
    setColumnSort,
    sort,
    users,
    user,
    getUser,
    userNotFound,
    setUserNotFound,
    loading,
  } = useUsersTable();

  useEffect(
    () => {
      if (user.id) {
        history.push(`userData/${user.id}`);
      }
    },
    [user],
  );

  function RenderTable(users) {
    return (
      <div className="Pulse-check-table-div">
        <Search
          method={getUser}
          userNotFound={userNotFound}
          setUserNotFound={setUserNotFound}
          loading={loading}
        >
          {!loading ? 'Search' : ''}
        </Search>
        <Table hover bordered>
          <thead>
            <tr>
              <th className="Id-column">
                <Sorting
                  onSortChange={newStatus => setColumnSort('id', newStatus)}
                  status={sort.category === 'id' ? sort.value : null}
                >
                  ID
                </Sorting>
              </th>
              <th className="Status-column">
                <Sorting
                  onSortChange={newStatus =>
                    setColumnSort('firstName', newStatus)
                  }
                  status={sort.category === 'firstName' ? sort.value : null}
                >
                  First Name
                </Sorting>
              </th>
              <th className="Analyst-column">
                <Sorting
                  onSortChange={newStatus =>
                    setColumnSort('lastName', newStatus)
                  }
                  status={sort.category === 'lastName' ? sort.value : null}
                >
                  Last Name
                </Sorting>
              </th>
              <th className="Required-date-column">
                <Sorting
                  onSortChange={newStatus => setColumnSort('email', newStatus)}
                  status={sort.category === 'email' ? sort.value : null}
                >
                  Email
                </Sorting>
              </th>
              <th className="Completed-date-column">
                <Sorting
                  onSortChange={newStatus =>
                    setColumnSort('facility', newStatus)
                  }
                  status={sort.category === 'facility' ? sort.value : null}
                >
                  Facility
                </Sorting>
              </th>
              <th className="Company-column">
                <Sorting
                  onSortChange={newStatus =>
                    setColumnSort('company', newStatus)
                  }
                  status={sort.category === 'company' ? sort.value : null}
                >
                  Company
                </Sorting>
              </th>
              <th className="Industry-column">
                <Sorting
                  onSortChange={newStatus =>
                    setColumnSort('country', newStatus)
                  }
                  status={sort.category === 'country' ? sort.value : null}
                >
                  Country
                </Sorting>
              </th>
            </tr>
          </thead>
          <tbody>
            {users !== []
              ? users.map(user => (
                  <tr
                    key={user.id}
                    onClick={() => history.push(`userData/${user.id}`)}
                  >
                    <td className="Id-column">{user.id}</td>
                    <td className="Status-column">{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.facility}</td>
                    <td>{user.company}</td>
                    <td>{user.country}</td>
                  </tr>
                ))
              : ''}
          </tbody>
        </Table>
      </div>
    );
  }

  return <div>{RenderTable(users)}</div>;
}

const UsersTable = () => <CreateUsersTable />;

export default UsersTable;
