import PropTypes from 'prop-types';

import authService from './auth-service';
import messagesEn from '../translations/en.json';
import messagesEs from '../translations/es.json';
import messagesFr from '../translations/fr.json';
import messagesZh from '../translations/zh.json';
import messagesPt from '../translations/pt.json';
import messagesRu from '../translations/ru.json';
import messagesCs from '../translations/cs.json';

const LanguageCodes = {
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  Chinese: 'zh',
  Portugeuse: 'pt',
  Russian: 'ru',
  Czech: 'cs'
};

export const IntlPropType = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired
});

class LanguageService {
  constructor() {
    this.prefixUserLanguage = 'language:';
    this.defaultLanguage = LanguageCodes.English;
    this.messages = {
      cs: messagesCs,
      en: messagesEn,
      es: messagesEs,
      fr: messagesFr,
      pt: messagesPt,
      zh: messagesZh,
      ru: messagesRu
    };

    this.language = this.resolveLanguage();
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(newLanguage) {
    const newLang = this.extractLanguage(newLanguage);
    if (this.messages[newLang]) {
      if (authService.isAuthenticated()) {
        // eslint-disable-next-line no-undef
        localStorage.setItem(this.getUserLanguageSettingForLocalStorage(), newLang);
      }
      this.language = newLang;
    }
  }

  getUserLanguageSettingForLocalStorage() {
    const { profileModel } = authService.getAuthInfo();
    return this.prefixUserLanguage + profileModel.email;
  }

  // language without region code
  extractLanguage = language => language.toLowerCase().split(/[-_]/)[0];

  getMessages() {
    return this.messages[this.language];
  }

  resolveLanguage() {
    let language;

    if (authService.isAuthenticated()) {
      // eslint-disable-next-line no-undef
      language = localStorage.getItem(this.getUserLanguageSettingForLocalStorage());
    } else {
      // eslint-disable-next-line no-undef
      const nav = navigator;
      language = this.extractLanguage(
        (nav.languages && nav.languages[0]) || nav.language || nav.userLanguage
      );
    }

    if (language && !this.messages[language]) {
      language = undefined;
    }

    if (!language) {
      language = this.defaultLanguage;
    }

    return language;
  }
}

export { LanguageCodes };
export default new LanguageService();
