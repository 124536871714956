import React, { useRef } from 'react';
import { Button, Form, Alert } from 'reactstrap';

const Search = ({
  method,
  children,
  data,
  userNotFound,
  setUserNotFound,
  status,
  loading,
}) => {
  const email = useRef(null);

  const useMethod = e => {
    method(e, email.current.value);
  };

  return (
    <div className="text-center">
      <Form className="form-signin" onSubmit={useMethod}>
        <input
          ref={email}
          className="form-control"
          type="email"
          name="email"
          id="emailInput"
          placeholder="email address"
          value={data ? data : undefined}
          disabled={data ? true : false}
          onChange={() => {
            if (userNotFound) setUserNotFound(false);
          }}
        />
        {status ? (
          <>
            <Button
              type="submit"
              color="primary"
              size="lg"
              disabled={status.loading}
              className={status.loading ? 'save-animation' : ''}
              block
            >
              {children}
            </Button>
            {status.success ? <Alert color="success">Success</Alert> : null}
            {status.error ? <Alert color="danger">{status.error}</Alert> : null}
          </>
        ) : (
          <>
            <Button
              type="submit"
              color="primary"
              size="lg"
              disabled={loading}
              className={loading ? 'save-animation' : ''}
              block
            >
              {children}
            </Button>
            {userNotFound ? (
              <Alert color="danger">Profile was not found.</Alert>
            ) : null}
          </>
        )}
      </Form>
    </div>
  );
};

export default Search;
